import { Box, Chip, Divider } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { t } from 'i18next'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../redux/reducers/rootReducer'
import { CustomTextfield } from '../../../../../../../shared-components/textfield/CustomTextField'
import { UploaderContainerStep4 } from '../UploadContainerStep4'
import styles from './ReleasesTypes.module.scss'
import { Controller } from 'react-hook-form'
import { IProperty } from '../../../../../../../../utils/types'

interface IProps {
  image: string
  id: string
  control?: any,
  index?: number,
  field?: IProperty,
  fieldArrayName?: string,
  setValue?: any,
  watch?: any,
  name?: string,
  errors?: any,
}

export const PropertyForm = (props: IProps) => {
  const {
    image,
    index,
    control,
    field,
    fieldArrayName,
    setValue,
    watch,
    errors,
    name
  } = props

  const uploadSession = useSelector(
    (state: RootState) => state.uploadSession.uploadSession
  )
  const accountInfo = useSelector((state: RootState) => state.user.usersData)

  const dataFile = watch(`${fieldArrayName}.property[${index}].file`)
  const dataFilePropertyPhotographer = watch(`${fieldArrayName}.property[${index}].author.author_signature`)
  const dataFilePropertyWitness = watch(`${fieldArrayName}.property[${index}].witness.witness_signature`)

  return (
    <Box display={'flex'} flexDirection='column' alignItems={'center'}>

      <Controller
        name={`${fieldArrayName}.property[${index}].file`}
        control={control}
        render={({ field: { ref, ...newField } }) =>
          <UploaderContainerStep4
            allowedExtensions={['JPG', 'PDF']}
            allowedTypes={['application/pdf', 'image/jpeg']}
            title={''}
            description={t('drop_pdf_jpg', { extensions: 'JPG, PDF' })}
            value={dataFile}
            handleAddedFile={(file: File | null) => {
              if(file) {
                setValue(`${fieldArrayName}.property[${index}].owner`, {
                  owner_name: '',
                  owner_address: '',
                  owner_date_signed: '',
                  owner_email: '',
                  owner_phone: '',
                  owner_signature: undefined,
                })
                setValue(`${fieldArrayName}.property[${index}].witness`, {
                  witness_name: `${accountInfo?.firstName} ${accountInfo?.lastName}`,
                  witness_date_signed: new Date(),
                  witness_signature: undefined,
                })
              }
              newField.onChange({
                target: {
                  value: file,
                  name: `${fieldArrayName}.property[${index}].file`
                }
              })
              // newField.onChange({target: { value: file, name: `${fieldArrayName}.property[${index}].file`}})
            }}
          />}
      />

      <>
        <Divider
          sx={{
            borderColor: 'grey.500',
            width: '100%',
            marginBottom: '20px',
          }}
        >
          <Chip label={t('OR')}></Chip>
        </Divider>
        <div style={{ position: 'relative' }} className={styles.propertyPdf}>
          {dataFile?.name && <div className={styles.mask}></div>}
          <div className={styles.termsAndConditions}>
            {t('Property_release_terms')}
          </div>

          <div>
            <img src={image} alt='img' className={styles.image} />
            <br />
            <span className={styles.titleText}>{t('Name')}:</span>{' '}
            <span>{name}</span>
            <br />
            <br />
            {/*<CustomTextfield*/}
            {/*  className={styles.textfield}*/}
            {/*  size='small'*/}
            {/*  value={*/}
            {/*    uploadSession?.images.find(*/}
            {/*      (item) => item.imageId.toString() === id*/}
            {/*    )?.title*/}
            {/*  }*/}
            {/*  disabled*/}
            {/*  variant='outlined'*/}
            {/*  label={t('Title')}*/}
            {/*/>*/}
            {/*<CustomTextfield*/}
            {/*  className={styles.textfield}*/}
            {/*  size='small'*/}
            {/*  variant='outlined'*/}
            {/*  label={t('Description')}*/}
            {/*  value={*/}
            {/*    uploadSession?.images.find(*/}
            {/*      (item) => item.imageId.toString() === id*/}
            {/*    )?.description*/}
            {/*  }*/}
            {/*  disabled*/}
            {/*/>*/}
            <Controller
              name={`${fieldArrayName}.property[${index}].created_date`}
              control={control}
              render={({ field: { ref, ...newField } }) =>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    className={styles.textfield}
                    label={t('Date Signed')}
                    format='dd-MM-yyyy'
                    disableFuture
                    {...newField}
                    slots={{
                      textField: CustomTextfield
                    }}
                    slotProps={{
                      textField: { size: 'small' }
                    }}
                  />
                </LocalizationProvider>}
            />
            <br />
            <br />
            <div className={styles.titleText}>
              {t('Property Owner / Authorized Representative')}
            </div>
            <br />
            <Controller
              name={`${fieldArrayName}.property[${index}].owner.owner_name`}
              control={control}
              render={({ field: { ref, ...newField } }) =>
                <CustomTextfield className={styles.textfield}
                  size='small'
                  error={errors?.owner?.owner_name}
                  helperText={errors?.owner?.owner_name ? t(errors?.owner?.owner_name?.message) : ''}
                  inputRef={ref}
                  variant='outlined'
                  label={t('Full Name')}
                  {...newField} 
                />
              }
            />
            <Controller
              name={`${fieldArrayName}.property[${index}].owner.owner_address`}
              control={control}
              render={({ field: { ref, ...newField } }) =>
                <CustomTextfield className={styles.textfield}
                                 size='small'
                                 inputRef={ref}
                                 variant='outlined'
                                 error={errors?.owner?.owner_address}
                                 helperText={errors?.owner?.owner_address ? t(errors?.owner?.owner_address?.message) : ''}
                                 label={t('Full Address')}
                                 {...newField} />}
            />
            <Controller
              name={`${fieldArrayName}.property[${index}].owner.owner_phone`}
              control={control}
              render={({ field: { ref, ...newField } }) =>
                <CustomTextfield className={styles.textfield}
                                 size='small'
                                 inputRef={ref}
                                 variant='outlined'
                                 error={errors?.owner?.owner_phone}
                                 helperText={errors?.owner?.owner_phone ? t(errors?.owner?.owner_phone?.message) : ''}
                                 label={t('Phone')}
                                 {...newField} />}
            />
            <Controller
              name={`${fieldArrayName}.property[${index}].owner.owner_email`}
              control={control}
              render={({ field: { ref, ...newField } }) =>
                <CustomTextfield className={styles.textfield}
                                 size='small'
                                 inputRef={ref}
                                 error={errors?.owner?.owner_email}
                                 helperText={errors?.owner?.owner_email ? t(errors?.owner?.owner_email?.message) : ''}
                                 variant='outlined'
                                 label={t('Email')}
                                 {...newField} />}
            />
            <Controller
              name={`${fieldArrayName}.property[${index}].owner.owner_date_signed`}
              control={control}
              render={({ field: { ref, ...newField } }) =>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    className={styles.textfield}
                    label={t('Date Signed')}
                    format='dd-MM-yyyy'
                    disableFuture
                    {...newField}
                    slots={{
                      textField: CustomTextfield
                    }}
                    slotProps={{
                      textField: { size: 'small' }
                    }}
                  />
                </LocalizationProvider>}
            />
            <div>
              <span className={styles.titleText}>{t('Signature')}</span>
              <Controller
                name={`${fieldArrayName}.property[${index}].owner.owner_signature`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <UploaderContainerStep4
                    title={''}
                    description={t('drop_pdf_jpg', { extensions: 'JPG' })}
                    value={field?.owner?.owner_signature}
                    handleAddedFile={(file: File | null) => {
                      newField.onChange({
                        target: {
                          value: file,
                          name: `${fieldArrayName}.property[${index}].owner.owner_signature`
                        }
                      })
                    }}
                  />}
              />
              <span
                className={styles.errorText}>{errors?.owner?.owner_signature ? t(errors?.owner?.owner_signature?.message) : ''}</span>
            </div>
            <br />
            <div className={styles.formContainer}>
              <span className={styles.titleText}>{t('Author Information')}</span>
              <br/>
              <Controller
                name={`${fieldArrayName}.property[${index}].author.author_name`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield className={styles.textfield}
                                   size='small'
                                   disabled
                                   inputRef={ref}
                                   variant='outlined'
                                   label={t('Full Name')}
                                   {...newField} />}
              />
              <Controller
                name={`${fieldArrayName}.property[${index}].author.author_address`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield className={styles.textfield}
                                   size='small'
                                   inputRef={ref}
                                   disabled={!!accountInfo?.addresses?.[0]?.address}
                                   variant='outlined'
                                   label={t('Full Address')}
                                   {...newField} />}
              />
              <Controller
                name={`${fieldArrayName}.property[${index}].author.author_phone`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield className={styles.textfield}
                                   size='small'
                                   disabled={!!accountInfo?.addresses?.[0]?.phone}
                                   inputRef={ref}
                                   variant='outlined'
                                   label={t('Phone')}
                                   {...newField} />}
              />
              <Controller
                name={`${fieldArrayName}.property[${index}].author.author_email`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield className={styles.textfield}
                                   size='small'
                                   disabled
                                   inputRef={ref}
                                   variant='outlined'
                                   label={t('Email')}
                                   {...newField} />}
              />
              <Controller
                name={`${fieldArrayName}.property[${index}].author.author_date_signed`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      className={styles.textfield}
                      label={t('Date Signed')}
                      format='dd-MM-yyyy'
                      disableFuture
                      {...newField}
                      slots={{
                        textField: CustomTextfield
                      }}
                      slotProps={{
                        textField: { size: 'small' }
                      }}
                    />
                  </LocalizationProvider>}
              />
            </div>
            <div>
              <span className={styles.titleText}>Signature</span>
              <Controller
                name={`${fieldArrayName}.property[${index}].author.author_signature`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <UploaderContainerStep4
                    title={''}
                    description={t('drop_pdf_jpg', { extensions: 'JPG' })}
                    value={dataFilePropertyPhotographer}
                    handleAddedFile={(file: File | null) => {
                      newField.onChange({
                        target: {
                          value: file,
                          name: `${fieldArrayName}.property[${index}].author.author_signature`
                        }
                      })
                    }}
                  />}
              />
              <span
                className={styles.errorText}>{errors?.author?.author_signature ? t(errors?.author?.author_signature?.message) : ''}</span>
            </div>
            <br />
            <p className={styles.titleText}>{t('witness_information')} </p>
            <Controller
              name={`${fieldArrayName}.property[${index}].witness.witness_name`}
              control={control}
              render={({ field: { ref, ...newField } }) =>
                <CustomTextfield className={styles.textfield}
                                 size='small'
                                 inputRef={ref}
                                 error={errors?.witness?.witness_name}
                                 helperText={errors?.witness?.witness_name ? t(errors?.witness?.witness_name?.message) : ''}
                                 variant='outlined'
                                 label={t('Full Name')}
                                 {...newField} />}
            />
            <Controller
              name={`${fieldArrayName}.property[${index}].witness.witness_date_signed`}
              control={control}
              render={({ field: { ref, ...newField } }) =>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    className={styles.textfield}
                    label={t('Date Signed')}
                    format='dd-MM-yyyy'
                    disableFuture
                    {...newField}
                    slots={{
                      textField: CustomTextfield
                    }}
                    slotProps={{
                      textField: { size: 'small' }
                    }}
                  />
                </LocalizationProvider>}
            />
            <div>
              <span className={styles.titleText}>{t('Signature')}</span>
              <Controller
                name={`${fieldArrayName}.property[${index}].witness.witness_signature`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <UploaderContainerStep4
                    title={''}
                    description={t('drop_pdf_jpg', { extensions: 'JPG' })}
                    value={dataFilePropertyWitness}
                    handleAddedFile={(file: File | null) => {
                      newField.onChange({
                        target: {
                          value: file,
                          name: `${fieldArrayName}.property[${index}].witness.witness_signature`
                        }
                      })
                    }}
                  />}
              />
              <span
                className={styles.errorText}>{errors?.witness?.witness_signature ? t(errors?.witness?.witness_signature?.message) : ''}</span>
            </div>
            {/*<Button*/}
            {/*  className={styles.newPropertyButton}*/}
            {/*  onClick={() => propertyAppend({ ...setInitialValuesProperty(accountInfo) })}*/}
            {/*>*/}
            {/*  {t('New Property Release')}*/}
            {/*</Button>*/}
          </div>
        </div>
      </>
    </Box>
  )
}
