import axios from 'axios';
import styles from './ModelReleaseList.module.scss'
import { Button } from '@mui/material'
import Notiflix from 'notiflix';

interface IProps {
  releases: any
}

export const ModelReleaseList = (props: IProps) => {
  const {releases} = props;

  const getFileName = (release: any) => {
    return release.filename.split('.')[0];
  };

  const handleDownload = async (linkUrl: string, filename: string) => {
    Notiflix.Loading.standard();
    axios({
      url: linkUrl,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`
      },
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      Notiflix.Loading.remove();
    }).catch((e) => {
      Notiflix.Loading.remove();
    });

  }
  return (
    <div className={styles.modelReleaseList}>
      {(releases || []).map((release: any) => (
        <Button className={styles.modelReleaseItem} key={release?.id} variant="contained" onClick={() => handleDownload(release?.link, release.filename)}>
          {`${getFileName(release)}`}
        </Button>
      ))}
    </div>
  )
}
