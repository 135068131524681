import styles from './FileDropArea.module.scss'
import { useRef } from 'react'

interface IProps {
  handleAddFiles: any;
  dropFormatText: string;
  allowedTypes?: string[];
}

export const FileDropArea = ({
    handleAddFiles,
    dropFormatText,
    allowedTypes = ['image/jpeg']
  }: IProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDragOver = (event: any) => {
    event.preventDefault()
  }

  const handleDragLeave = (event: any) => {
    event.preventDefault()
  }

  const handleDrop = (event: any) => {
    event.preventDefault()

    const files = Array.from(event.dataTransfer.files)
    .filter((file: any) =>
      allowedTypes.includes(file.type)
    )
    handleAddFiles(files)
  }
  const handleClick = () => {
    fileInputRef.current?.click();
  }
  const handleFileInput = (event: any) => {
    const files = Array.from(event.target.files)
    handleAddFiles(files)
  }
  return (
    <div
      onClick={handleClick}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      className={styles.container}
    >
      <p>{dropFormatText}</p>
      <input
        ref={fileInputRef}
        type="file"
        style={{ display: 'none' }}
        multiple
        onChange={handleFileInput}
      />
    </div>
  )
}
