import { Alert, Tab, Tabs } from '@mui/material'
import { Fragment, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TabPanel } from '../../../../../../shared-components/TabPanel'
import styles from './Step4ImageElement.module.scss'
import { PropertyForm } from './releases-types/Property'
import { Model } from './releases-types/Model'
import { MinorForm } from './releases-types/Minor'

interface IProps {
  image: any,
  index?: number,
  id: number
  description: string
  title: any
  setReleases?: React.Dispatch<SetStateAction<any>>
  releases?: any
  addedToSelectionElements?: string[],
  propertyFields?: any,
  modelFields?: any,
  control?: any
  propertyAppend?: any,
  modelAppend?: any,
  minorFields?: any,
  minorAppend?: any,
  tabs?: any,
  valuesForm?: any;
  fieldArrayName?: string;
  errors?: any;
  modelRemove?: any;
  minorRemove?: any,
  setValue?: any,
  watch?: any,
  name?: string,
}

export const Step4ImageElement = (props: IProps) => {
  const {
    image,
    id,
    index,
    errors,
    modelFields,
    fieldArrayName,
    propertyFields,
    minorAppend,
    control,
    modelRemove,
    modelAppend,
    minorFields,
    minorRemove,
    valuesForm,
    setValue,
    watch,
    name,
  } = props

  const { t } = useTranslation()
  const [tabValue, setTabValue] = useState('property[0]')
  //const [tabs, setTabs] = useState([...propertyFields, ...modelFields])

  const constructTabs = () => {
    const newArray = {...valuesForm?.images?.[index || 0]};
    let newTabs = []
    newTabs = [
      {type: 'property', name: 'Property', index: 0, value: `property[0]`},
      {type: 'model', name: 'Model 1', index: 0,  value: `model[0]`},
      {type: 'minor', name: 'Minor 1', index: 0,  value: `minor[0]`}
    ]
    if(newArray?.model?.length > 1) {
      const modelArray =  [...newArray?.model];
      modelArray.shift()
      const models = modelArray.map((model: any, index: any) => {
        return {
          type: 'model', name: `Model ${index + 2}`, index: index + 1,  value: `model[${index + 1}]`
        }
      })
      newTabs = [...newTabs, ...models]
    }
    if(newArray?.minor?.length > 1) {
      const minorArray =  [...newArray?.minor];
      minorArray.shift()
      const minor = minorArray.map((model: any, index: any) => {
        return {
          type: 'minor', name: `Minor ${index + 2}`, index: index + 1, value: `minor[${index + 1}]`
        }
      })
      newTabs = [...newTabs, ...minor]
    }
    return newTabs

  }

  const handleChangeTab = (event: any, newValue: any) => {
    setTabValue(newValue)
  }
  const handleDeleteModel = (index: number) => {
    setTabValue('property[0]')
    modelRemove(index)
  }
  const handleDeleteMinor = (index: number) => {
    setTabValue('property[0]')
    minorRemove(index)
  }
  return (
    <div className={styles.container}>
      <Alert
        sx={{ justifyContent: 'center', alignItems: 'center' }}
        severity='info'
      >
        {t('To add more MODEL/MINOR RELEASES click on the NEW MODEL/MINOR RELEASE button. Complete all releases before IMAGE TO LICENSING!')}
      </Alert>
      <br></br>
      <Tabs
        className={styles.tabs}
        value={tabValue}
        onChange={handleChangeTab}
        centered
        TabIndicatorProps={{ style: { height: 0 } }}
      >
        {constructTabs().map((tab: any, index: any) => (
          <Tab
            value={tab.value}
            key={`${tab.name}-${index}-${tab?.index}`}
            label={tab.name}
            sx={
              index > 2
                ? {
                  '&.Mui-selected': {
                    color: '#c11b1e'
                  },
                  marginRight: 1
                }
              : {
                color: '#c11b1e',
                border: '1px solid #c11b1e',
                marginRight: 1,
                '&.Mui-selected': {
                  backgroundColor: '#c11b1e',
                  color: '#fff'
                },
              }
            }
          />
        ))}
      </Tabs>

      {constructTabs().map((tab: any, index: any) => {
        return (
        <Fragment key={`${tab.name}-${index}-${tab?.index}`}>
          <TabPanel value={tabValue} index={tab.value}>
            <div className={styles.tabContainer}>
              <div>
                {tab.type === 'property' ? (<>
                    <PropertyForm
                      index={tab?.index}
                      control={control} 
                      field={propertyFields[tab?.index]}
                      image={image}
                      name={name}
                      watch={watch}
                      errors={errors?.property ? errors?.property?.[tab?.index] : {}}
                      setValue={setValue}
                      fieldArrayName={fieldArrayName}
                      id={id?.toString()}
                    />
                  </>
                ) : tab.type === 'model' ? (
                  <Model
                    index={tab?.index}
                    control={control}
                    watch={watch}
                    name={name}
                    setValue={setValue}
                    errors={errors?.model ? errors?.model?.[tab?.index] : {}}
                    fieldArrayName={fieldArrayName}
                    field={modelFields[tab?.index]}
                    image={image}
                    modelRemove={handleDeleteModel}
                    id={id?.toString()}
                    modelAppend={modelAppend}
                  />
                ) : (
                  <>
                    <MinorForm
                      field={minorFields[tab?.index]}
                      minorAppend={minorAppend}
                      index={tab?.index}
                      watch={watch}
                      name={name}
                      setValue={setValue}
                      errors={errors?.minor ? errors?.minor?.[tab?.index] : {}}
                      fieldArrayName={fieldArrayName}
                      control={control}
                      image={image}
                      onDeleteMinor={handleDeleteMinor}
                      id={id?.toString()}
                    />
                  </>
                )}
              </div>
            </div>
          </TabPanel>
        </Fragment>
      )})}
    </div>
  )
}
