import { object, array, string, number, lazy, mixed } from 'yup'
import { areObjectValuesEmpty } from './helpers'

// @ts-ignore
export default object().shape({
  images: array()
    .of(
      object().shape({
        name: string(),
        imageId: number().required(),
        property: array()
          .of(
            object().shape({
              file: mixed().notRequired(),
              owner: lazy((values: any) => {
                const {owner_name, owner_address, owner_date_signed, owner_email, owner_phone, owner_signature} = values
                if (owner_name || owner_address || owner_date_signed || owner_email || owner_phone || owner_signature) {
                  return object().shape({
                    owner_name: string().required('name_required'),
                    // owner_address: string().required('address_required'),
                    owner_address: string().nullable(),
                    owner_date_signed: string().required('date_sign_required'),
                    // owner_email: string().email('email_not_valid').required('email_required'),
                    owner_email: string().email('email_not_valid').nullable(),
                    owner_signature: mixed().required('signature_required'),
                    // owner_phone: string().required('phone_required'),
                    owner_phone: string().nullable(),
                  })
                }
                return mixed().notRequired()
              }),
              witness: mixed().when(['file', 'owner.owner_name', 'owner.owner_signature'], (file, schema) =>
                {
                  if(file[1] || file[2]) {
                    return object().shape({
                      witness_signature: mixed().required('signature_required'),
                      witness_name: string().required('name_required'),
                    })
                  }
                  else {
                    return mixed().notRequired()
                  }
                }
              ),
              author: mixed().when(['file', 'owner.owner_name', 'owner.owner_signature'], (file, schema) =>
                {
                  if(file[1] || file[2]) {
                    return object().shape({
                      author_signature: mixed().required('signature_required'),
                    })
                  }
                  else {
                    return mixed().notRequired()
                  }
                }
              ),
            })),
        minor: array()
          .of(
            object().shape({
              file: mixed(),
              name_minor: mixed().when('file', (file, schema) =>
                {
                  if(file[0]) {
                    return mixed().required('name_required')
                  }
                  else {
                    return mixed().notRequired()
                  }
                }
              ),
              minor: lazy((values: any) => {
                const {minor_dob, minor_name, minor_path} = values
                if (minor_dob || minor_name || minor_path) {
                  return object().shape({
                    minor_dob: string().required('minor_dob_required'),
                    minor_name: string().required('minor_name_required'),
                    // minor_path: mixed().required('signature_required'),
                    minor_path: mixed().notRequired(),
                  })
                }
                return mixed().notRequired()
              }),
              parent: mixed().when(['file', 'minor.minor_name', 'minor.minor_path'], (file, schema) =>
                {
                  if(file[1] || file[2]) {
                    return object().shape({
                      parent_name: string().required('name_required'),
                      // parent_address: string().required('address_required'),
                      parent_address: string().notRequired(),
                      parent_date_signed: string().required('date_sign_required'),
                      // parent_email: string().required('email_required'),
                      // parent_phone: string().required('phone_required'),
                      parent_email: string().notRequired(),
                      parent_phone: string().notRequired(),
                      parent_signature: mixed().required('signature_required'),
                    })
                  }
                  else {
                    return mixed().notRequired()
                  }
                }
              ),
              witness: mixed().when(['file', 'minor.minor_name', 'minor.minor_path'], (file, schema) =>
                {
                  if(file[1] || file[2]) {
                    return object().shape({
                      witness_date_signed: string().required('date_sign_required'),
                      witness_name: string().required('name_required'),
                      witness_signature: mixed().required('signature_required'),
                    })
                  }
                  else {
                    return mixed().notRequired()
                  }
                }
              ),
              photographer: mixed().when(['file', 'minor.minor_name', 'minor.minor_path'], (file, schema) =>
                {
                  if(file[1] || file[2]) {
                    return object().shape({
                      photographer_signature: mixed().required('signature_required'),
                    })
                  }
                  else {
                    return mixed().notRequired()
                  }
                }
              ),
            })),
        model: array()
          .of(
            object().shape({
              file: mixed(),
              name_model: mixed().when('file', (file, schema) =>
                {
                  if(file[0]) {
                    return mixed().required('name_required')
                  }
                  else {
                    return mixed().notRequired()
                  }
                }
              ),
              model: lazy((values: any) => {
                const {model_date_signed, model_dob, model_email, model_name, model_phone, model_signature, model_address} = values
                if (model_date_signed || model_dob || model_email || model_name || model_phone || model_signature || model_address) {
                  return object().shape({
                    model_date_signed: string().required('date_sign_required'),
                    // model_dob: string().required('date_birth_required'),
                    model_dob: string().notRequired(),
                    model_email: string(),
                    model_name: string().required('name_required'),
                    model_phone: string().notRequired(),
                    model_signature: mixed().required('signature_required'),
                    model_address: string().notRequired(),
                    model_path:  mixed().notRequired(),
                  })
                }
                return mixed().notRequired()
              }),

                photographer: mixed().when(['file', 'model.model_name', 'model.model_path'], (file, schema) =>
                  {
                    if(file[1] || file[2]) {
                      return object().shape({
                        photographer_signature: mixed().required('signature_required'),
                      })
                    }
                    else {
                      return mixed().notRequired()
                    }
                  }
                ),
              witness: mixed().when(['file', 'model.model_name', 'model.model_path'], (file, schema) =>
                {
                  if(file[1] || file[2]) {
                    return object().shape({
                      witness_date_signed: string().required('date_sign_required'),
                      witness_name: string().required('name_required'),
                      witness_signature: mixed().required('signature_required'),
                    })
                  }
                  else {
                    return mixed().notRequired()
                  }
                }
              ),

            }))
      })
    )

})
